import React, { useState } from "react";
import { Link } from "react-router-dom";

function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}

export default function Cope() {
	const skills = [
		{
			skill: "Take a nap",
			message:
				"Sometimes you just need a nap you know? Also most teenagers do not get enough sleep, so it is good for you anyway.",
		},
		{
			skill: "Call your sister",
			message:
				"She loves you very much and can provide a different kind of support than your parents.",
		},
		{
			skill: "Walk the Dog",
			message:
				"Excersise gets your brain working and can help you feel better or distract from stress.",
		},
		{
			skill: "Talk to your mom or dad",
			message:
				"Your parents love you very much, they can help you to feel better",
		},
		{
			skill: "Play the Piano",
			message:
				"Playing piano is good because it can disctract you. It also shows that you are actually good at something. You can also use it to express your emotions.",
		},
		{
			skill: "do Breathing Excercises",
			message:
				"Breath in for 3 seconds, now out for three seconds. Repeat. Breathing excercises can help you to calm down and feel better. They can also help you to focus on the present moment.",
		},
		{
			skill: "make a goal",
			message:
				"Make a goal for yourself. It can be something small like 'I will do my homework today' or something big like 'I will get a 4.0 GPA this year'. Goals can help you to feel better because they give you something to work towards.",
		},
		{
			skill: "Spend time with your friends",
			message: "Friends are good for you. They can help you to feel better.",
		},
		{
			skill: "Get it done",
			message: "Just do it. You can do it. You are strong. Procrastinating will not help you.",
		},
		{
			skill: "Turn off your phone",
			message: "Stop using social media until you feel better. It will only make you feel worse.",
		}
	];
	const [skill, setSkill] = useState(skills[getRandomInt(skills.length + 1)]);

	return (
		<div className="cope">
			<h1 className="cope__title">You should {skill.skill}</h1>
			<p className="cope__message">{skill.message}</p>
			<p
				className="cope__regen cope__btn"
				onClick={() =>
					setSkill(skills[getRandomInt(skills.length - 1)])
				}
			>
				Try Something Else
			</p>
			<Link to="/" className="cope__btn">
				Back
			</Link>
		</div>
	);
}
