import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./style.css";

import Home from "./Home";
import Cope from "./Cope";
function App() {
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/cope" element={<Cope />} />
			</Routes>
		</Router>
	);
}

export default App;
