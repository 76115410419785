import React from "react";
import {Link} from "react-router-dom";

export default function Home() {
	return (
		<div className="home">
			<h1>How are you feeling?</h1>
			<Link to="/cope" className="home__btn">
				Tense
			</Link>
			<Link to="/cope" className="home__btn">
				Lonley
			</Link>
			<Link to="/cope" className="home__btn">
				Isolated
			</Link>
			<Link to="/cope" className="home__btn">
				Anxious
			</Link>
			<Link to="/cope" className="home__btn">
				Fidgety
			</Link>
			<Link to="/cope" className="home__btn">
				Sad
			</Link>
			<Link to="/cope" className="home__btn">
				Depressed
			</Link>
			<Link to="/cope" className="home__btn">
				Meaningless
			</Link>
		</div>
	);
}
